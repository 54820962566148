<template>
  <div class="wrapper">
    <b-card bg-variant="dark">
      <div class="animated fadeIn">
        <b-row>
          <b-col sm="6" md="4">
            <b-card class="bg-secondary text-center" style="height: 80%;">
              <blockquote class="card-blockquote">
                <img src="@/icon/1661274.png" style="border-radius: 10px; width: 40px;">
                <p> {{ $t('wdm16.20') }} </p>
                <footer>
                  <cite :title="$t('wdm16.20')">
                    <a href="https://www.google.com/maps/dir/41.0362587,29.1587105/google+maps+wisdomera/@41.0566366,28.8752767,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14cabbce9c31f443:0xdbb0a69fe9a80fe5!2m2!1d28.9118955!2d41.0164703" target="_target">WisdomEra A.Ş, MERKEZEFENDİ MAHALLESİ, Eski Londra Asfaltı Cd. NO:11, 34015 Zeytinburnu/İstanbul</a>
                  </cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="4">
            <b-card class="bg-secondary text-center" style="height: 80%;">
              <blockquote class="card-blockquote">
                <img src="@/icon/110598.png" style="border-radius: 10px; width: 40px;">
                <p> {{ $t('wdm16.36') }} </p>
                <br>
                <footer>
                  <cite title="info@wisdomera.io"><a href="mailto:info@wisdomera.io?Subject=''" target="_top">info@wisdomera.io</a></cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="4">
            <b-card class="bg-secondary text-center" style="height: 80%;">
              <img src="@/icon/1047163.png" style="border-radius: 10px; width: 40px;">
              <blockquote class="card-blockquote">
                <p> {{ $t('wdm16.35') }} </p>
                <br>
                <footer>
                  <cite title="Telefon"><span><a href="tel:02124151093">02124151093</a> </span></cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.3997988700316!2d28.911779!3d41.0165084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabbce9c31f443%3A0xdbb0a69fe9a80fe5!2sWisdomEra%20A.%C5%9E!5e0!3m2!1sen!2str!4v1574957724299!5m2!1sen!2str" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
          </b-col>
        </b-row>
        <br>
        <web-bottom></web-bottom>
      </div>
    </b-card>
  </div>
</template>

<script>
import WebBottom from '@/components/widgets/WebBottom';
import { mapGetters } from 'vuex';
export default {
  name: 'Contact',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WebBottom
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style>


</style>

